// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Videos from "../../blocks/videos/src/Videos";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";


const routeMap = {
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Videos:{
 component:Videos,
path:"/Videos"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;