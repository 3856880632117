import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { UploadVideoBody, VideoData } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data?: VideoData[];
  themeData?: { id: string; title: string }[];
  themeItem?: { id: string; title: string };
  handleSelectTheme?: Function;
  handleBack?: Function;
  selectedTheme?: string;
  uploadVideo: Function;
  handleItemLoad?: Function;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  isItemLoadStart: boolean;
  themeData: { id: string; title: string }[];
  isThemeSelected: boolean;
  selectedTheme: string;
  videoListingData: VideoData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getThemeApiID: string = "";
  getAllVideosFromSelectedTheme: string = "";
  sendPostApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: true,
      isItemLoadStart: false,
      themeData: [],
      isThemeSelected: false,
      selectedTheme: "",
      videoListingData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getThemeApiID) {
        if (errorResponse) {
          this.setState({
            isLoading: false,
          });
          this.showAlert("Error", configJSON.errorRepostMessage);
        } else {
          this.setState({
            themeData: responseJson.data,
            isLoading: false,
          });
        }
        this.parseApiCatchErrorResponse(errorResponse);
      }
      if (apiRequestCallId === this.getAllVideosFromSelectedTheme) {
        if (errorResponse) {
          this.showAlert("Error", configJSON.errorRepostMessage);
        } else {
          this.setState({
            videoListingData: responseJson.data,
            isLoading: false,
          });
          this.state.videoListingData?.forEach((item) => {
            item.isItemLoading = false;
          });
        }
        this.parseApiCatchErrorResponse(errorResponse);
      }
      if (apiRequestCallId === this.sendPostApiCallId) {
        if (errorResponse) {
          this.setState({ isLoading: false });
          this.showAlert("Error", configJSON.errorRepostMessage);
        } else {
          this.setState({ isLoading: false });
          this.showAlert(
            "Success",
            `${responseJson.data?.attributes.title} uploaded successfully`
          );
        }
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getThemes = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.contentRepostApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getThemeApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.end_point_getAllTheme
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType_get
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleItemLoad = (video_id: string, state: boolean) => {
    let newVideoLibrary: VideoData[] = [];
    this.state.videoListingData.forEach((item) => {
      if (item.video_title === video_id) {
        item.isItemLoading = state;
      }
      newVideoLibrary.push(item);
    });
    this.setState({
      videoListingData: newVideoLibrary,
    });
  };

  getVideosFromSelectedTheme = (themeId: string) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.contentRepostApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllVideosFromSelectedTheme = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.end_point_getVideosFromSelectedTheme + "?theme_id=" + themeId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType_get
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  uploadVideo = (body: UploadVideoBody) => {
    this.setState({ isLoading: true });
    let formData = new FormData();
    formData.append("theme_id", body.theme_id);
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("video", body.video);
    const header = {
      "Content-Type": configJSON.contentFormData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.end_point_uploadVideo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType_post
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBack = () => {
    this.setState({ isThemeSelected: false });
  };

  handleSelectTheme = async (themeId: string) => {
    this.setState({
      isThemeSelected: !this.state.isThemeSelected,
      selectedTheme: themeId,
    });
    this.getVideosFromSelectedTheme(themeId);
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getThemes();
  }
  // Customizable Area End
}
